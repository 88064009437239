function doVerifyToken(token) {
    var data = {
        'action': shortcodeOpts.action_check_recaptcha,
        'security': shortcodeOpts.ajax_nonce,
        'token': token
    };

    return new Promise(resolve => {
        jQuery.post(shortcodeOpts.ajaxurl, data, function (response) {
            resolve(response);
        });
    });
}

async function verifyToken(token) {
    return await doVerifyToken(token).then(response => {
            return response.result;
        },
        error => {
            console.error("Verify token response:" + response);
        }
    );
};

var verifyCallback = function (response) {
    jQuery(shortcodeOpts.form_query_selector).data('grecaptcha-token', response);
};
var onloadCallback = function () {
    grecaptcha.render('g-recaptcha-id', {
        'sitekey': shortcodeOpts.recaptcha_sitekey,
        'theme': 'light',
        'callback': verifyCallback,
    });
};